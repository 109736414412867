import { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const urlServer = "https://smartsticker.ferit.hr/";

export const useFetch = (url, initialParams = {}, fetchResult) => {
  const [params, updateParams] = useState(initialParams)
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const queryString = Object.keys(params)
      .map((key) => encodeURIComponent(key) + '=' +
      encodeURIComponent(params[key])).join('&')
  useEffect(() => {
    const fetchData = async () => {
      if (params==={}) return
      setIsLoading(true)
      try {
        const response = await fetch(`${urlServer}${url}?${queryString}`,
          {method:'POST', 'Access-Control-Allow-Origin': '*'})
        const result = await response.json()
        if (response.ok) {
          fetchResult(result);
          setData(result)
        } else {
          setHasError(true)
          setErrorMessage(result)
        }
      } catch (err) {
        setHasError(true)
        setErrorMessage(err.message)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [url, params])
  return { data, isLoading, hasError, errorMessage, updateParams }
}

export function notify(level, text) {
  let fun = toast.info;
  switch(level) {
    case "info":    fun = toast.info;    break;
    case "warning": fun = toast.warning; break;
    case "success": fun = toast.success; break;
    case "error":   fun = toast.error;   break;
    default:        fun = toast;         break;
  }
  const options = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    autoClose: 3000,
    pauseOnHover: false
  }
  fun(text,options);
}
