/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import image1 from "assets/img/EU.png";
import image2 from "assets/img/FOND.png";
import image3 from "assets/img/PROGRAM.png";
import image4 from "assets/img/logoSFSB_hr.png";
import image5 from "assets/img/logoFERIT_hr.png";
//import im from "assets/img/logoSAFU_hr.png";
//import im from "assets/img/HR.png";
const imageList = [
  image1, image2, image3, image4, image5
];
import Clearfix from "components/Clearfix/Clearfix.js";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const {hr} = props;
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
  <div className={classes.section}>
    <footer className={footerClasses} style={{backgroundColor: "white"}}>
      <div className={classes.container}>
        <div className={classes.center}>
          <List className={classes.list}>
            {imageList.map((item, index) => {
              return(
                <ListItem key={index} className={classes.inlineBlock}>
                  <img src={item} height="150px"
                    className={classes.block} />
                </ListItem>
            );})}
          </List>
        </div>
      </div>
    </footer>
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center} style={{color: "Navy", fontSize: "15px"}}>
        {hr ? "Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj"
            : "The project was co-financed by the European Union from the European Regional Development Fund"}
        <br/>
        {hr ? "Izradu Internetske stranice sufinancirala je Europska unija iz Europskog fonda za regionalni razvoj"
            : "The website was co-financed by the European Union from the European Regional Development Fund"}
        <br/>
        2020. &copy;  <a href="https://www.facebook.com/VanjaVidakovicPhotography"
           className={classes.inlineBlock} target="_blank"
           >Vi-Va Multimedia</a>
          {hr ? "  |  Sadržaj materijala isključiva je odgovornost Fakulteta elektrotehnike, računarstva i informacijskih tehnologija Osijek  |  "
              : "  |  The content of the material is the sole responsibility of the Faculty of Electrical Engineering, Computer Science and Information Technology Osijek  |  "}
          <a href="https://strukturnifondovi.hr/en/"
             className={classes.inlineBlock} target="_blank"
          >{hr ? "Strukturni fondovi":"Structural funds"}</a>
          <br/>
          <br/>
        </div>
      </div>
    </footer>
  </div>);
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
