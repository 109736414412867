import React, { useState, useCallback } from "react";
import {notify, useFetch} from "constants.js";
import _ from "lodash";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/loginStyle.js";

const useStyles = makeStyles(styles);

const initialState = {
  user: "",
  pass: ""
};

var md5 = require('md5');

export default function SectionLogin(props) {
  const [state, setState] = React.useState(initialState);
  const {logged} = props;
  const onChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  };

  const { data, isLoading, hasError, errorMessage, updateParams } = useFetch('auth',{},fetchResult)

  function fetchResult(r) {
    console.log(r);
    if(logged) {
      notify("info","Odjavljen korisnik: " + data[0].user);
      props.setLogged(false);
      setState(initialState);
      return;
    }
    if(!_.isEmpty(r)) {
      notify("success", "Prijavljen korisnik: " + r[0].user);
      props.setLogged(true);
    } else {
      notify("error", "Prijava nije uspjela");
      setState(initialState);
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    updateParams({
      type: '0',
      user: state.user,
      pass: md5(state.pass)
    })
  }

  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <form className={classes.form} onSubmit={handleSubmit}>
                <CardHeader color="info" className={classes.cardHeader}>
                  <h4>Login</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="User name"
                    id="user"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: state.user,
                      disabled: logged,
                      onChange: onChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: state.pass,
                      disabled: logged,
                      onChange: onChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputIconsColor}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg" type="submit">
                    {isLoading ? "Loading" : logged ? "Logout" : "Login"}
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
