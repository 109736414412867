export const languageText = {
  titleShort:{hr:"Pametna naljepnica",gb:"Smart Sticker"},
  titleLong:{hr:"Pametna naljepnica za mjerenje i praćenje uvjeta skladištenja i transporta proizvoda",
             gb:"Smart sticker for measuring and monitoring storage and transportation conditions of products"},
  euFund:{hr:"Europski fond za regionalni razvoj, Poziv „Ulaganje u znanost i inovacije - Prvi poziv",
          gb:"European Regional Development Fund, Call \"Investing in Science and Innovation - First Call\""},
  financed: {hr: "Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj", gb: "The project was co-financed by the European Union from the European Regional Development Fund"},
  content:{hr:"Sadržaj",gb:"Content"},
  goal1:{hr:"Izrada prototipa i uvođeenje pametne naljepnice u prehrambenu, elektroničku i kemijsku industriju.",
         gb:"Prototyping and introduction of smart sticker in food, electronics and chemical industry."},
  goal2:{hr:"Definiranje novih standarda osiguranja kontinuiranog praćenja proizvoda tijekom procesa skladištenja i distribucije.",
         gb:"Defining new standards to ensure continuous monitoring of products during the storage and distribution process."},
  goal3:{hr:"Upoznavanje šire javnosti i gospodarkske subjekte s rezultatima istražianja na ovom projektu.",
         gb:"Introducing the general public and economic entities with the results of research on this project."},
  goal4:{hr:"Kao jedan od mogućih ciljeva uvođenja prototipa pametne naljepnice u industriju moguće je definirati nove standarde koji osiguravaju kontinuirano praćenje proizvoda (prehrambeni, elektronički, kemijski) tijekom procesa skladištenja i distribucije. S rezultatima ovih djelatnosti upoznati će se široka javnost i gospodarski subjekti, putem predavanja, objavljivanja znanstvenih radova, prijenosa znanja kroz radionice i konferencije te demonstracijske aktivnosti na sajmovima.",
         gb:"As one of the possible goals of introducing a smart sticker prototype in the industry, it is possible to define new standards that ensure continuous monitoring of products (food, electronic, chemical) during the storage and distribution process. The results of these activities will be introduced to the general public and economic entities through lectures, publication of scientific papers, transfer of knowledge through workshops and conferences, and demonstration activities at fairs."},
  strategy: {
    hr:"Projekt doprinosi specifičnom strateškom cilju 1 Strategije pametne specijalizacije: \"Povećanje kapaciteta znanstveno-istraživačkog sektora za provedbu vrhunskih istraživanja koja odgovaraju potrebama gospodarstva.\" Projekt djelomično doprinosi i cilju 4.1.3 Specifični strateški cilj 3: \"Modernizacija i diverzifikacija hrvatskog gospodarstva kroz ulaganja poslovnog sektora u istraživanje, razvoj i inovacije,\" jer doprinosi jačanju odnosa između akademije i industrije te stvara preduvjete za komercijalizaciju rezultata istraživanja i razvoja javnog sektora.",
    gb:"The project contributes to specific strategic goal 1 of the smart specialization strategy: \"Increasing the capacity of the scientific research sector to conduct cutting-edge research that meets the needs of the economy.\" The project partly contributes to goal 4.1.3 Specific strategic goal 3: \"Modernization and diversification of the Croatian economy through business sector investments in research, development and innovation,\" because it contributes to strengthening the relationship between academia and industry and creates preconditions for commercialization of public sector research and development results ."},
  sustainability: {
    hr:"Projekt je u skladu s tematskim prioritetnim područjem (TPP) \"Hrana i bio-ekonomija\" te pod-tematskim prioritetnim područjem (PTPP) \"Održiva proizvodnja i prerada hrane\". Indikativne IRI teme kojima projekt doprinosi u postizanju ciljeva S3 su (1.) zdravstvena sigurnost hrane i (2.) očuvanje proizvoda. U sklopu horizontalnih tema KET i ICT, povezane indikativne IRI teme kojima projekt doprinosi u postizanju ciljeva S3 su (1.) ključne razvojne tehnologije za pakiranje i skladištenje hrane radi čuvanja od kontaminacije, poboljšanja trajnosti, poboljšanja troškovne i ekološke učinkovitosti kao i povećanje održivosti, (2.) procesna i ugradbena računalna automatizacija te upravljački procesi.",
    gb:"The project is in line with the thematic priority area (TPP) \"Food and Bio-Economy\" and the sub-thematic priority area (PTPP) \"Sustainable Food Production and Processing\". Indicative IRI topics to which the project contributes in achieving the S3 goals are (1.) food safety and (2.) product preservation. Within the horizontal themes of KET and ICT, related indicative IRI themes to which the project contributes to achieving the S3 objectives are (1.) key development technologies for food packaging and storage to protect against contamination, improve durability, improve cost and environmental efficiency and increase sustainability, (2.) process and embedded computer automation and control processes."},
  contribution: {
    hr:"Ovim projektom će se povećati trenutno niska razina automatizacije procesa skladištenja i distribucije paletiziranih i pojedinačnih proizvoda. Nakon proizvodnje određenog proizvoda, kupac, distributer i prodavač nemaju podatke o uvjetima skladištenja i distribucije. Stalnim praćenjem proizvoda i njegove okoline (temperatura, vlaga, vibracija, ...) od proizvodnje do korisnika omogućuje se svim akterima uvid u uvjete skladištenja i distribucije. Glavni korisnici pametne naljepnice bili bi proizvodna industrija robe široke potrošnje, distributeri robe široke potrošnje, industrija hrane, lijekova i kemikalija, trgovački lanci, proizvođači elektroničke opreme i potrošačke elektronike, itd. Rezultati ovog projektnog prijedloga su primjeri prototipa pametnih naljepnica. Za razliku od postojećih sustava, pametnu naljepnicu bi mogao očitati svaki kupac upotrebom pametnog telefona. Na taj način kupac bi bio siguran da kupuje ispravno skladišten i distribuiran proizvod.",
    gb:"This project will increase the currently low level of automation of the process of storage and distribution of palletized and individual products. After the production of a particular product, the buyer, distributor and seller have no information on the conditions of storage and distribution. Constant monitoring of the product and its environment (temperature, humidity, vibration, ...) from production to the user provides all actors with insight into the conditions of storage and distribution. The main users of the smart sticker would be the consumer goods manufacturing industry, the consumer goods distributors, the food, pharmaceutical and chemical industries, retail chains, manufacturers of electronic equipment and consumer electronics, etc. The results of this project proposal are examples of smart sticker prototypes. Unlike existing systems, a smart sticker could be read by any customer using a smartphone. This way the customer would be sure to buy a properly stored and distributed product."},
  innovation: {
    hr:"Trenutno ne postoji proizvod kao što je pametna naljepnica predstavljena u ovoj projektnoj prijavi. Provedba projekta provodi se kroz fazu industrijskog istraživanja i eksperimentalnog razvoja prototipa pametne naljepnice prema načelu ekonomičnosti, učinkovitosti i djelotvornosti. U fazi industrijskog istraživanja razmatrati će se poboljšanje procesa skladištenja i distribucije proizvoda izradom prototipa pametne naljepnice od njenih sastavnih dijelova u laboratorijskom okruženju s malim pilot linijama za provjeru generičke tehnologije. Izraditi će se prototip u laboratorijskom okruženju sa simuliranim uvjetima okoline. U fazi eksperimentalnog razvoja cilj je ostvariti daljnja tehnička poboljšanja predstavljenog prototipa te izrade konačnog novog proizvoda pametne naljepnice. Definirati će se koncept i dokumentacija novog proizvoda prototipa pametne naljepnice. Na kraju projekta, izraditi će se više prototipova koji će odgovarati zadanim parametrima i funkcionalnostima sukladno izrađenoj dokumentaciji.",
    gb:"There is currently no product like the smart sticker presented in this project application. The implementation of the project is carried out through the phase of industrial research and experimental development of the smart sticker prototype according to the principle of economy, efficiency and effectiveness. In the industrial research phase, the improvement of the product storage and distribution process by making a prototype of a smart sticker from its components in a laboratory environment with small pilot lines for testing generic technology will be considered. A prototype will be developed in a laboratory environment with simulated environmental conditions. In the phase of experimental development, the goal is to achieve further technical improvements of the presented prototype and the production of the final new product of the smart sticker. The concept and documentation of the new smart sticker prototype product will be defined. At the end of the project, several prototypes will be made that will correspond to the given parameters and functionalities in accordance with the prepared documentation."},
  efficiency: {
    hr:"S obzirom na relativno male računalne zahtjeve pametne naljepnice, male dimenzije komponenti i izrazito malu potrošnju, realizacija će se temeljiti na mikroupravljačkoj tehnologiji. Koristiti će se komponente s visokom pouzdanosti i dugim radnim vijekom, savitljive baterije, savitljive i tanke elektroničke pločice, integrirani krugovi i senzori vrlo malih dimenzija, kao i tehnologije za omatanje i zaštitu od oštećenja. Metodologije koje će se implementirati u opisanu računalnu okolinu uključuju primjenu algoritama za efikasnu izmjenu informacija s obzirom na malu potrošnju električne energije te pristup velikom broju informacijama sa senzora.",
    gb:"Given the relatively small computational requirements of the smart label, the small dimensions of the components and the extremely low power consumption, the realization will be based on microcontroller technology. High reliability and long service life components, flexible batteries, flexible and thin electronic boards, integrated circuits and sensors of very small dimensions, as well as wrapping and damage protection technologies will be used. The methodologies that will be implemented in the described computer environment include the application of algorithms for efficient exchange of information due to low power consumption and access to a large amount of information from sensors."},
  home: {hr:"Početna",gb:"Home"},
  about: {hr:"O projektu",gb:"Project details"},
  partners: {hr:"Partneri projekta",gb:"Project partners"},
  news: {hr:"Novosti",gb:"News"},
  contact: {hr:"Kontakt",gb:"Contact"},
  login: {hr:"Login",gb:"Login"},
  webPage: {hr:"Internet stranica",gb:"Web page"},
  ferit: {hr:"Fakultet elektrotehnike, računarstva i informacijskih tehnologija Osijek",gb:"Faculty of Electrical Engineering, Computer Science and Information Technology Osijek"},
  sfsb: {hr:"Strojarski fakultet Slavonski Brod",gb:"Faculty of Mechanical Engineering Slavonski Brod"},
  link: {hr:"Poveznica",gb:"Link"},
  sendMessage: {hr: "Pošalji poruku", gb: "Send message"},
  projectManager: {hr:"voditelj projekta", gb:"project manager"},
  translate: function(lang) {
    let x = {};
    Object.keys(languageText).forEach(i=>x[i]=languageText[i][lang]);
    x.hr = lang==="hr" ? true : false;
    return x;
  }
};
