/*eslint-disable*/
import React from "react";
import PropTypes from 'prop-types';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import {LanguageSelect} from "components/Language/LanguageSelect.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { menuItems, lang, handleLanguage} = props;
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {menuItems.map((item, index) => {
          return(
            <AnchorLink key={index} href={item.href}  className={classes.navLink}
              offset='70' style={{padding: "0"}}>
              <Button color="transparent" className={classes.navLink}
                style={{fontWeight: "bold"}}>
                {item.text}
              </Button>
            </AnchorLink>
          );
        })}
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="language-select"
          title="Language"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}>
          <Button
            onClick={handleLanguage}
            color="transparent"
            className={classes.navLink}
          >
          <LanguageSelect lang={lang} width={35} height={35}/>
        </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
