import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import image1 from "assets/img/measure1.png";
import image2 from "assets/img/measure2.png";
import image3 from "assets/img/measure3.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function SectionImagesMeasure() {
  const classes = useStyles();
  const imageHeight = "200px;!important";
  const imageMargin = "1px";
  const images = [image1,image2,image3];
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer align="center">
          {images.map((item,index) => { return (
            <GridItem key={index} xs={12} sm={6} md={6} lg={4}>
              <img height={imageHeight}
                src={item}
                alt="..." style={{margin: {imageMargin}}}
              />
            </GridItem>
          )})}
        </GridContainer>
      </div>
    </div>
  );
}
