import { container } from "assets/jss/material-kit-react.js";
import image from "assets/img/news.jpg";

const carouselStyle = {
  section: {
    minHeight: "110vh",
    maxHeight: "1600px",
    overflow: "hidden",
    padding: "70px 0",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    backgroundImage: "url(" + image + ")"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  title: {
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 39%)"
  }
};

export default carouselStyle;
