import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import { Divider } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";

import image1 from "assets/img/news/3/1.jpg";
import image2 from "assets/img/news/3/2.jpg";
import image3 from "assets/img/news/3/3.jpg";
import image4 from "assets/img/news/3/4.jpg";

import image5 from "assets/img/news/2/1.png";
import image6 from "assets/img/news/2/2.png";
import image7 from "assets/img/news/2/3.png";

import image8 from "assets/img/news/1/1.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionNews(props) {
  const {hr} = props;
  const news = [
    { title: hr ? "Zapošljavanje mladog istraživača"
                : "Young researcher employement",
      text: hr ? "Raspisan je natječaj za zapošljavanje mladog znanstvenog istraživača za rad na projektu."
               : "A competition has been announced for the employment of a young scientific researcher to work on the project.",
      link: "https://www.ferit.unios.hr/fakultet/novosti-i-dogadanja/8547#8547",
      location: "Osijek, Croatia",
      images: [image8]
    },
    { title: hr ? "Nova internet stranica projekta"
                : "New project web site",
      text: hr ? "Nova internet stranica projekta izrađena je 11.5.2020."
               : "New project web site is made on 11 May 2020",
      location: "Osijek, Croatia",
      link: "https://smartsticker.ferit.hr/",
      images: [image5,image6,image7]
    },
    { title: hr ? "Potpisivanje ugovora projekta"
                : "Signing project contract",
      text: hr ? "Ugovor za Pametnu naljepnicu potpisan je 20.12.2019. u Ministarstvu znanosti i obrazovanja."
               : "Contract for the \"Smart Sticker\" project is signed on 20 December 2019 in the Ministry of science and education.",
      link: "https://mzo.gov.hr/istaknute-teme/natjecaji-196/potpisani-novi-ugovori-u-sklopu-operativnoga-programa-konkurentnost-i-kohezija-2014-2020/3618",
      location: "Zagreb, Croatia",
      images: [image1,image2,image3,image4]
    },
  ];
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.title}>{hr?"Novosti":"News"}</h2>
        <GridContainer justify="center">
          {news.map((article,index) => {return(
            <GridItem key={index} xs={12} sm={12} md={6} lg={4} className={classes.marginAuto}>
              <Card carousel>
                <CardHeader>
                  <br/><h3><strong>
                    {article.title}</strong></h3>
                  <h4>{article.text}</h4>
                  <h4>
                  <a href={article.link} className={classes.inlineBlock}
                    target="_blank" rel="noopener noreferrer">Poveznica</a></h4>
                </CardHeader>
                <Divider />
                <Carousel {...settings}>
                  {article.images.map((i,_index) => {return(
                    <div key={_index}>
                      <img src={i} alt="" className="slick-image" />
                      <div className="slick-caption">
                        <h4>
                          <LocationOn className="slick-icons" />
                          {article.location}
                        </h4>
                      </div>
                    </div>
                  )})}
                </Carousel>
              </Card>
            </GridItem>
          )})}
        </GridContainer>
      </div>
    </div>
  );
}
